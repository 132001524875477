import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, LinkBox, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "1rem 0 1rem 0",
	"background": "--color-darkL2",
	"quarkly-title": "Footer-16"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"lg-flex-direction": "column"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"width": "20%",
			"lg-margin": "0px 0px 35px 0px",
			"lg-width": "100%",
			"href": "/"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "--headline3",
			"children": "arcadeontherun"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "80%",
			"lg-width": "100%",
			"justify-content": "flex-end",
			"align-items": "center",
			"sm-flex-direction": "column",
			"sm-align-items": "flex-start",
			"md-flex-direction": "column",
			"md-align-items": "flex-start",
			"md-display": "flex",
			"md-grid-gap": "24px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"margin": "0px 50px 0px 10px",
			"lg-align-items": "flex-start",
			"justify-content": "center",
			"display": "flex",
			"flex-direction": "row",
			"flex-wrap": "wrap",
			"md-justify-content": "flex-start",
			"sm-margin": "0px 0 30px 0",
			"grid-template-columns": "repeat(5, 1fr)",
			"grid-gap": "8px 0",
			"sm-grid-template-columns": "1fr",
			"md-display": "flex",
			"md-grid-gap": "16px",
			"md-margin": "0px 50px 0px 0px"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 1rem 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"href": "#about",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "--light",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"children": "ABOUT"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 1rem 0px 0",
			"display": "flex",
			"href": "#features",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "--light",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"children": "FEATURES"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "--light",
			"href": "#faq",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"display": "flex",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"children": "FAQ"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"href": "https://play.google.com/store/apps/details?id=com.dirtybit.fire&hl=en",
			"padding": "12px 24px 12px 24px",
			"min-width": "15%",
			"color": "--light",
			"background": "--color-secondary",
			"text-decoration-line": "initial",
			"font": "--lead",
			"border-radius": "8px",
			"margin": "0px 0px 0px 0px",
			"sm-margin": "0px 0px 0px 0px",
			"sm-text-align": "center",
			"hover-transition": "background-color 0.2s linear 0s",
			"hover-background": "--color-orange",
			"transition": "background-color 0.2s linear 0s",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center",
			"align-content": "center",
			"children": "Download"
		}
	}
};

const Header1 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" height="auto" />
		<Box {...override("box")}>
			<LinkBox {...override("linkBox")}>
				<Text {...override("text")} />
			</LinkBox>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Link {...override("link")} />
					<Link {...override("link1")} />
					<Link {...override("link2")} />
				</Box>
			</Box>
			<Link {...override("link3")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Header1, { ...Section,
	defaultProps,
	overrides
});
export default Header1;